<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mb-2"
        >
          <span class="card-title" />
          <b-button
            variant="primary"
            @click.prevent="openModal"
          >
            <span class="text-nowrap">Add Work</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows.works ? rows.works :0"
        :is-loading="loader"
        :search-options="{
          skipDiacritics: true,
          enabled: true,
          externalQuery: searchTerm,
        }"
        mode="remote"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        @on-column-filter="columnFilter"
        @on-sort-change="sortFn"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span v-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>
          <!-- Column: task -->
          <span v-if="props.column.field === 'task'">
            Total : {{ props.row.total_task }}, Pending: {{ props.row.pending_task }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span class="d-flex justify-content-around">

              <!--                              @click="$router.push({name:'task-chat', params:{name:props.row.id}})"-->
              <feather-icon
                id="tooltip-target-4"
                color="dark"
                icon="ActivityIcon"
                class="mr-50 cursor-pointer"
                @click.prevent="$router.push({name:'tasks', query:{work_id:props.row.id}})"
              />
              <b-tooltip
                target="tooltip-target-4"
                triggers="hover"
              >
                Tasks
              </b-tooltip>
              <feather-icon
                id="tooltip-target-1"
                color="cyan"
                icon="EyeIcon"
                class="mr-50 cursor-pointer"
                @click.prevent="openEditModal(props.row, true)"
              />
              <b-tooltip
                target="tooltip-target-1"
                triggers="hover"
              >
                View
              </b-tooltip>
              <feather-icon
                id="tooltip-target-2"
                color="dark"
                icon="Edit2Icon"
                class="mr-50 cursor-pointer"
                @click.prevent="openEditModal(props.row, false)"
              />
              <b-tooltip
                target="tooltip-target-2"
                triggers="hover"
              >
                Edit
              </b-tooltip>
              <feather-icon
                id="tooltip-target-3"
                color="red"
                icon="TrashIcon"
                class="mr-50 cursor-pointer"
                @click.prevent="deleteWork(props.row.id)"
              />
              <b-tooltip
                target="tooltip-target-3"
                triggers="hover"
              >
                Delete
              </b-tooltip>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10','15','20','25']"
                class="mx-1"
                @change="selectedPage(pageLength)"
              />
              <span class="text-nowrap "> of {{ rows.meta && rows.meta.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="page"
                :total-rows="rows.meta && rows.meta.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <!--  work add  modal-->
    <b-modal
      id="modal-add-work"
      ref="work-add-modal"
      :title="disabled ? 'View Work' : (edit?'Edit Work': 'Add Work')"
      ok-disabled
      cancel-disabled
      @hidden="hideModal('modal-add-work')"
    >
      <validation-observer ref="workAdd">
        <b-form
          @reset.prevent="clearForm"
        >
          <b-form-group
            label="Work Name"
            label-for="work-name"
          >
            <validation-provider
              #default="{ errors }"
              vid="title"
              name="work title"
              rules="required"
            >
              <b-form-input
                v-model="work.title"
                :state="errors.length > 0 ? false:null"
                :disabled="disabled"
                placeholder="Enter work title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="!edit"
            label="Company"
            label-for="company"
          >
            <validation-provider
              #default="{ errors }"
              vid="id"
              name="Company"
              rules="required"
            >
              <v-select
                v-model="work.company_id"
                :options="companiesOptions"
                label="name"
                :reduce="option => option.id"
                :disabled="disabled"
                placeholder="Select company"
                input-id="id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="!disabled"
            label="Add Attachment"
          >
            <validation-provider
              #default="{ errors }"
              vid="file"
              name="file"
            >
              <b-form-file
                v-model="attachment"
                :state="errors.length > 0 ? false:null"
                placeholder="Select files for upload"
                drop-placeholder="Drop file here..."
                multiple
                @change="uploadImage($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <br> <small>Upload appropriate files or images</small><br>
            <div
              v-for="(preview, index) in attachment"
              :key="index"
              style=" float: left"
              class="m-md-1 image-area"
            >
              {{ preview.name }}
              <a
                class="remove-image confirm-image"
                style="display: inline;"
                @click="removeImg(preview.id)"
              >&#215;</a>
            </div>
          </b-form-group>
          <b-form-group
            label="Description"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              vid="description"
              name="description"
              rules="required"
            >
              <b-form-textarea
                id="textarea-state"
                v-model="work.description"
                :disabled="disabled"
                :state="errors.length > 0 ? false:null"
                placeholder="Enter at least 10 characters"
                rows="3"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="edit"
            :label="disabled? 'Status' : 'Select Role'"
            label-for="role"
          >
            <validation-provider
              #default="{ errors }"
              name="role"
              rules="required"
            >
              <b-input-group class="=input-group-merge">
                <b-form-select
                  v-model="work.status"
                  :disabled="disabled"
                  :options="options"
                  size="lg"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="edit"
            label="Files"
            label-for="files"
          >
            <b-list-group horizontal>
              <b-row>
                <b-col
                  v-for="(f, index) in filePreviews"
                  :id="f.id"
                  :key="index"
                  md="12"
                >
                  <span>{{ index+1 }}. </span>
                  <b-img
                    v-if="fileType.includes(f.file_type)"
                    thumbnail
                    fluid
                    width="75"
                    height="75"
                    :src="f.file_path"
                  />
                  <a
                    v-else
                    :href="f.file_path"
                    target="_blank"
                  ><b-badge
                    pill
                    variant="info"
                    style="white-space: pre-wrap;"
                  >{{ f.file_name }}</b-badge></a>
                  <span class="float-right cursor-pointer"><feather-icon
                    icon="XIcon"
                    @click="removeUploadedImage(f.id)"
                  /></span>
                </b-col>
              </b-row>
            </b-list-group>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button
          size="md"
          variant="outline-secondary"
          @click.prevent="hideModal('modal-add-work')"
        >
          {{ disabled ? 'OK' : 'Cancel' }}
        </b-button>
        <b-button
          v-if="disabled"
          size="md"
          variant="outline-primary"
          @click.prevent="disabled=false"
        >
          Edit
        </b-button>
        <b-button
          v-if="!disabled"
          size="md"
          variant="primary"
          @click.prevent="addWork(edit)"
        >
          Submit
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BBadge, BPagination, BFormSelect, BFormFile, BButton, BRow, BCol, BFormTextarea,
  BFormGroup, BFormInput, BForm, BTooltip, BInputGroup, BImg, BListGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormSelect,
    BButton,
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BForm,
    BTooltip,
    BFormFile,
    BInputGroup,
    BListGroup,
    BImg,
    vSelect,
  },
  data() {
    return {
      fileType: ['jpg', 'jpeg', 'png', 'svg', 'webp'],
      options: [
        { value: 'active', text: 'Active', selected: true },
        { value: 'completed', text: 'Completed' },
      ],
      filePreviews: [],
      attachment: [],
      disabled: false,
      required,
      work: {},
      edit: false,
      loader: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Title',
          field: 'title',
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Search Title',
            trigger: 'enter',
          },
        },
        {
          label: 'Company name',
          field: 'company_name',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Search Company',
            trigger: 'enter',
          },
        },
        {
          label: 'Task',
          field: 'task',
          sortable: false,
        },
        {
          label: 'Created',
          field: 'created_at',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      searchTerm: '',
      page: 1,
      data: {},
    }
  },
  computed: {
    companiesOptions() {
      return this.$store.getters['user/company/GET_COMPANIES'].companies
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        active : 'light-success',
        inactive     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    rows() {
      return this.$store.getters['user/work/GET_WORKS']
    },
  },
  mounted() {
    this.getWorkList()
    this.getCompanies()
  },
  methods: {
    getWorkList(data = null, current_page = 1) {
      this.loader = true
      document.onkeydown = () => false
      this.$store.dispatch('user/work/getWorkList', {
        params: {
          page: current_page,
          per_page: this.pageLength,
          searchTerm: this.searchTerm,
          title: data ? data.title : '',
          company_name: data ? data.company_name : '',
          status: data ? data.status : '',
          sort_by: data ? data.field : '',
          sort_order: data ? data.type : '',
          company_id: this.$route.query.company_id,
        },
      }).then(() => {
        this.loader = false
        document.onkeydown = () => true
      })
    },
    // eslint-disable-next-line camelcase
    getCompanies() {
      this.$store.dispatch('user/company/getCompanyList')
    },
    selectedPage() {
      this.getWorkList(null, this.page)
    },
    handleChangePage(page) {
      this.getWorkList(null, page)
    },
    columnFilter(params) {
      this.getWorkList(params.columnFilters)
    },
    sortFn(params) {
      this.getWorkList(params[0])
    },

    openModal() {
      this.disabled = false
      this.edit = false
      this.clearForm()
      this.$bvModal.show('modal-add-work')
    },
    hideModal(modalId) {
      this.$nextTick(() => {
        this.$bvModal.hide(modalId)
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        this.clearForm()
      })
    },
    clearForm() {
      for (const key in this.task) {
        this.task[key] = null
      }
      this.attachment = []
    },
    addWork(edit) {
      if (edit) {
        this.editWork()
        return
      }
      this.$refs.workAdd.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('title', this.work.title)
          formData.append('company_id', this.work.company_id)
          formData.append('description', this.work.description)
          formData.append('status', this.work.status)
          formData.append('file[]', this.work.file)
          this.$store.dispatch('user/work/storeWork', formData).then(() => {
            this.hideModal('modal-add-work')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                text: 'Work added',
                variant: 'success',
              },
            })
            this.$refs.workAdd.reset()
            this.getWorkList()
          })
            .catch(error => {
              this.$refs.workAdd.setErrors(error.response.data.errors)
            })
        }
      })
    },
    openEditModal(item, show = false) {
      this.edit = true
      this.disabled = show
      this.$store.dispatch('user/work/getSelectedWork', {
        work_id: item.id,
      }).then(response => {
        this.work.id = response.data.id
        this.work.title = response.data.title
        this.filePreviews = response.data.file
        this.work.description = response.data.description
        delete this.work.company_id
        delete this.work.file
        this.work.status = response.data.status
        this.$bvModal.show('modal-add-work')
      })
    },
    editWork() {
      this.$refs.workAdd.validate().then(success => {
        if (success) {
          this.$store.dispatch('user/work/editWork', this.work)
            .then(() => {
              this.hideModal('modal-add-work')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  text: 'Work updated',
                  variant: 'success',
                },
              })
              this.edit = false
              this.$refs.workAdd.reset()
              this.getWorkList()
            })
            .catch(error => {
              console.log(error)
              this.$refs.workAdd.setErrors(error.response.data.errors)
            })
        }
      })
    },
    uploadImage(event) {
      this.attachment = event.target.files
      if (this.attachment && this.attachment.length < 1) {
        this.filePreviews = []
      }
      const formData = new FormData()
      this.attachment.forEach((image, key) => {
        formData.append(`files[${key}]`, image)
      })
      this.$store.dispatch('user/handleFileUpload', formData)
        .then(response => {
          this.work.file = response.data.file_id
          let count = 0
          this.attachment.forEach(item => {
            item.id = this.work.file[count]
            count += 1
          })
        })
    },
    removeImg(item_id) {
      this.attachment.splice(this.attachment.map(item => item.id).indexOf(item_id), 1)
      this.task.file.splice(this.task.file.indexOf(item_id), 1)
    },
    removeUploadedImage(data) {
      this.$store.dispatch('user/file/deleteFile', {
        id: data,
      }).then(() => {
        document.getElementById(data).remove()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File removed successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    deleteWork(item) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('user/work/deleteWork', item).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Work has been deleted.',
              showConfirmButton: false,
              timer: 1500,
            })
            this.getWorkList()
          })
        }
      })
    },
    // searchHandler() {
    //   this.getCompanyList(this.page, this.pageLength, this.searchTerm)
    // },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.fileinput-remove,
.fileinput-upload {
  display: none;
}
.image-area {
  position: relative;
  width: 25%;
  background: #333;
}

.image-area img {
  max-width: 100%;
  height: auto;
  overflow-wrap: break-word;

}

.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #FFF;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}

.remove-image:hover {
  background: #E54E4E;
}

.remove-image:active {
  background: #E54E4E;
}
</style>
